import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import PageTitileTag from "../../components/page-title-tag";
import CustomFooter from "../../components/custom-footer";
import whiteLogo from "../../images/muuvLogoWhite.png";
import { Input, Spin, notification } from "antd";
import { useState } from "react";


const AccTermination = () => {
  const navigate = useNavigate();
  const [theEmail, setTheEmail] = useState("")
  const [isAgreed, setIsAgreed] = useState<any>(false)
  const [isBusy, setIsBusy] = useState(false)

  //handleDelete
  const handleDelete = () => {
    if (!isAgreed) {
      notification.warning({ message: "Please check the box to agree" })
    }
    else if (!theEmail) {
      notification.warning({ message: "Please enter your email" })
    }
    else {
      setIsBusy(true)
      setTimeout(() => {
        notification.success({ message: "Account deletion initiated!" })
      }, 2000)
      setIsBusy(false)
      setTheEmail("")
      setIsAgreed(false)
    }
  }

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="grid grid-rows-3 w-full h-full overflow-y-auto">
          <div className="row-span-1 bg-green-800 h-full p-[35px]">
            <button className="h-10 w-10" onClick={() => navigate("/")}>
              <ChevronLeftIcon className="cursor-pointer text-white h-5 w-5" />
            </button>
            <div className="flex items-center h-full text-white flex-col">
              {/* <p className="font-semibold text-2xl">Movis Logistics:</p>
              <p className="font-semibold lg:text-[42px] text-[30px] mt-[5px]">
                Privacy Policy
              </p> */}
              <img className="w-[150px]" src={whiteLogo} alt="logo" />
            </div>
          </div>

          <div className="h-full">
            <div className="2xl:px-[100px] lg:px-[35px] px-5 mb-10">
              <p className="font-semibold lg:text-[42px] text-[30px] text-center mt-[80px]">
                Account Termination
              </p>

              <div className="flex justify-center items-center mt-[30px]">
                <PageTitileTag pageTitle={`Last Updated - December 2023`} />
              </div>

              <div className="mt-[30px]">
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  This page serves as confirmation that you have requested the termination of your account on MuuvExpress. Please be aware that this action is irreversible. By proceeding, you acknowledge and understand that:
                </p>

                {/* next */}
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  <span className="text-black">1. Data Deletion:</span>
                  <br />
                  All data associated with your account, including but not limited to personal information, preferences, and any uploaded content, will be permanently deleted.
                  <br />
                  <br />
                </p>

                {/* next */}
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  <span className="text-black">Loss of Access:</span>
                  <br />
                  You will no longer have access to your account, and any associated services or features will be discontinued immediately.
                  <br />
                  <br />
                </p>

                {/* next */}
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  <span className="text-black">No Recovery:</span>
                  <br />
                  Once the account termination process is complete, it is impossible to recover any information or restore access. Please ensure you have backed up any necessary data before proceeding.
                  <br />
                  <br />
                </p>

                {/* next */}
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  <span className="text-black">Communications Cessation:</span>
                  <br />
                  You will no longer receive any communications, notifications, or updates from MuuvExpress after the termination is finalized.
                  <br />
                  <br />
                </p>

                {/* next */}
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  By confirming your decision below, you acknowledge that you have read and understood the implications of terminating your account on MuuvExpress. If you have any concerns or questions, please contact our support team before proceeding.
                </p>
                <br />


                <div className="mt-5 flex gap-3 items-center">
                  <input className="h-5 w-5" type="checkbox" value={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)} disabled={isBusy} />
                  <p className="text-red-500">I understand and acknowledge the irreversible nature of this action, and I wish to proceed with the termination of my account.</p>
                </div>

                <div className="mt-5 flex gap-3 items-center lg:w-[600px]">
                  <Input className="h-[40px]" placeholder="Enter your email" type="email" value={theEmail} onChange={(e) => setTheEmail(e.target.value)} disabled={isBusy} />
                  <button className="px-3 h-[40px] bg-red-500 text-white rounded-[5px] w-full disabled:cursor-not-allowed" onClick={() => handleDelete()} disabled={isBusy}>
                    {
                      isBusy ? <Spin />
                        :
                        "Delete My Account"
                    }
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-[100px]">
              <CustomFooter />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccTermination;
