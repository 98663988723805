import React from 'react'
import { EnvelopeOpenIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { Input } from 'antd'
import ContactMap from './contact-map';

const ContactComponent = () => {

    const { TextArea } = Input;

    return (
        <>
            <div className="px-5 lg:px-[100px] mt-5">

                <div className='lg:grid lg:grid-cols-2 w-full gap-x-[30px] mt-5'>
                    <div className="w-full mb-10 lg:mb-0">
                        <div className='lg:flex lg:gap-[30px] w-full'>
                            <Input className='h-[50px] bg-gray-200 rounded-[5px]' placeholder='Full Name' />
                            <Input className='h-[50px] bg-gray-200 rounded-[5px] mt-5 lg:mt-0' type='email' placeholder='Email' />
                        </div>

                        <div className="mt-5">
                            <Input className='h-[50px] bg-gray-200 rounded-[5px]' placeholder='Subject' />
                        </div>

                        <div className="mt-5">
                            <TextArea className=' bg-gray-200 rounded-[5px]' placeholder='Message' style={{ height: "150px" }} />
                        </div>

                        <div className="mt-5">
                            <button className='h-[50px] w-[150px] flex justify-center items-center rounded-[5px] bg-green-800 text-white'>
                                Submit
                            </button>
                        </div>
                    </div>

                    <div>
                        <ContactMap />
                    </div>
                </div>

                <div className='lg:grid lg:grid-cols-3 w-full gap-x-[50px] mt-[50px]'>
                    <div className='w-full p-5 flex justify-center items-center bg-gray-200'>
                        <div className='flex gap-[20px] items-center'>
                            <div className='flex justify-center items-center bg-green-800 h-20 w-20 rounded-full'>
                                <MapPinIcon className='h-10 w-10 text-white' />
                            </div>
                            <div>
                                <p className='font-semibold'>Our Address</p>
                                <p>5th Floor Elephant House, 214 Broad Street Marina Lagos</p>
                            </div>
                        </div>
                    </div>

                    <div className='w-full p-5 flex justify-center items-center bg-gray-200 my-5 lg:my-0'>
                        <div className='flex gap-[20px] items-center'>
                            <div className='flex justify-center items-center bg-green-800 h-20 w-20 rounded-full'>
                                <PhoneIcon className='h-10 w-10 text-white' />
                            </div>
                            <div>
                                <p className='font-semibold'>Phone Number</p>
                                <p>+234 704 668 7521</p>
                            </div>
                        </div>
                    </div>

                    <div className='w-full p-5 flex justify-center items-center bg-gray-200 mb-5 lg:mb-0'>
                        <div className='flex gap-[20px] items-center'>
                            <div className='flex justify-center items-center bg-green-800 h-20 w-20 rounded-full'>
                                <EnvelopeOpenIcon className='h-10 w-10 text-white' />
                            </div>
                            <div>
                                <p className='font-semibold'>Email Us</p>
                                <p>info.ng@movis-logistics.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactComponent
