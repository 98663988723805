import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import PageTitileTag from "../../components/page-title-tag";
import CustomFooter from "../../components/custom-footer";
import whiteLogo from "../../images/muuvLogoWhite.png";


const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="grid grid-rows-3 w-full h-full overflow-y-auto">
          <div className="row-span-1 bg-green-800 h-full p-[35px]">
            <button className="h-10 w-10" onClick={() => navigate("/")}>
              <ChevronLeftIcon className="cursor-pointer text-white h-5 w-5" />
            </button>
            <div className="flex items-center h-full text-white flex-col">
              {/* <p className="font-semibold text-2xl">Movis Logistics:</p>
              <p className="font-semibold lg:text-[42px] text-[30px] mt-[5px]">
                Privacy Policy
              </p> */}
              <img className="w-[150px]" src={whiteLogo} alt="logo" />
            </div>
          </div>

          <div className="h-full">
            <div className="2xl:px-[100px] lg:px-[35px] px-5 mb-10">
              <p className="font-semibold lg:text-[42px] text-[30px] text-center mt-[80px]">
                App Privacy Policy
              </p>

              <div className="flex justify-center items-center mt-[30px]">
                <PageTitileTag pageTitle="Last Updated - December 2023" />
              </div>

              <div className="mt-[30px]">
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  Welcome to Muuvexpress Platform (referred to as "we", "our", or "us"). We are committed to protecting your privacy and providing a safe and secure experience for our users. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our logistics platform services through our mobile application (the "App"). By using our App, you consent to the practices described in this Privacy Policy.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  What Information Do We Collect?
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  1. Information We Collect:
                  <br />
                  <br />
                  a) Information Provided by You:
                  - For Normal Users: We collect your name, email address, password, profile image, and location data.
                  - For Drivers: In addition to the information collected for normal users, we also collect driver's license details, car details, and continuous background location data while the App is in use or running in the background.
                  <br />
                  b) Automatically Collected Information:
                  - We may collect information about your device, including your IP address, device type, operating system, and browser type.
                  - We use cookies and similar technologies to track user activity on our App.

                  <br />
                  <br />
                  2. How We Use Your Information: <br />
                  <br />
                  We use the collected information for the following purposes:
                  - To provide and improve our logistics services.
                  - To personalize user experience and deliver relevant content.
                  - To process transactions and communicate with you about your orders.
                  - To ensure the safety and security of our platform.
                  - To comply with legal obligations.
                  <br />
                  <br />
                  3. How We Share Your Information: <br />
                  <br />
                  We may share your information with third parties in the following circumstances:
                  - With drivers to facilitate the delivery process.
                  - With service providers who help us in providing our services.
                  - With law enforcement or government authorities in response to legal requests or to prevent illegal activities.
                  - In case of a merger, acquisition, or sale of all or a portion of our assets.
                  <br />
                  <br />
                  4. Location Data: <br />
                  <br />
                  We collect location data to provide our logistics services effectively. For drivers, we collect continuous background location data to track their location when delivering packages. Location data is crucial for the proper functioning of our services.
                  <br />
                  <br />
                  5. Data Security: <br />
                  <br />
                  We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.
                  <br />
                  <br />
                  6. Your Choices: <br />
                  <br />
                  We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the updated Privacy Policy on our App.
                  <br />
                  <br />
                  7. Changes to this Privacy Policy: <br />
                  <br />
                  We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the updated Privacy Policy on our App.
                  <br />
                  <br />
                  8. Contact Us: <br />
                  <br />
                  If you have any questions about this Privacy Policy or our data practices, please contact us at info@movis-logistics.com/+233303966099.
                  <br /><br />
                  By using our App, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.
                </p>


                {/* next */}

                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  How Do We Use Your Email Address?
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  By submitting your email address on this app, you agree to
                  receive emails from us_ You can cancel your participation in any
                  of these email lists at any time by clicking on the opt-out link
                  or other unsubscribe option that is included in the respective
                  email. We only send emails to people who have authorized us to
                  contact them, either directly, or through a third party. We do
                  not send unsolicited commercial emails, because we hate spam as
                  much as you do_ By submitting your email address, you also agree
                  to allow us to use your email address for customer audience
                  targeting on sites like Facebook where we display custom
                  advertising to specific people who have opted-in to receive
                  communications from us. Email addresses submitted only through
                  the order-processing page will be used for the sole purpose of
                  sending you information and updates pertaining to your order.
                  If, however, you have provided the same email to us through
                  another method, we may use it for any of the purposes stated in
                  this Policy. Note: If at any time you would like to unsubscribe
                  from receiving future emails, we include detailed unsubscribe
                  instructions at the bottom of each email.
                </p>




              </div>
            </div>

            <div className="mt-[100px]">
              <CustomFooter />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
