import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import HomePage from './pages/home/homePage';
import PrivacyPolicy from './pages/privacy-policy/privacyPolicy';
import AccTermination from './pages/acc-termination/termination';
import ContactComponent from './pages/contact/contact';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/privacy",
      element: <PrivacyPolicy />,
    },
    // {
    //   path: "/contact",
    //   element: <ContactComponent />,
    // },
    {
      path: "/support",
      element: <ContactComponent />,
    },
    {
      path: "/termination",
      element: <AccTermination />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
