import React, { useRef } from 'react'
import CustomNavbar from '../../components/navbar'
import HomeSlider from './homeSlider'
import { Typography } from '@material-tailwind/react'
import TitleTag from '../../components/titleTag'
import { StopCircleIcon } from '@heroicons/react/24/outline'
import CustomFooter from '../../components/custom-footer'
import DownloadAppComponent from '../../components/downloadAppComponent'
import ContactComponent from '../contact/contact'
import muuveBike from "../../images/muuveBike.jpg"
import youngMan from "../../images/portrait-young-african-guy-accep.webp"

const HomePage = () => {

    const homeDiv = useRef<HTMLDivElement>(null);
    const servicesDiv = useRef<HTMLDivElement>(null);
    const aboutDiv = useRef<HTMLDivElement>(null);
    const contactDiv = useRef<HTMLDivElement>(null);


    const scrollToDiv = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='h-screen overflow-hidden'>
            <CustomNavbar homeClick={() => scrollToDiv(homeDiv)} servicesClick={() => scrollToDiv(servicesDiv)} aboutClick={() => scrollToDiv(aboutDiv)} contactClick={() => scrollToDiv(contactDiv)} />

            <div ref={homeDiv} className='h-full overflow-y-auto'>
                <div className='h-[600px]'>
                    <HomeSlider />
                </div>

                {/* sec 1 */}
                <div className='h-auto lg:flex lg:justify-between bg-gray-100 py-[30px] lg:py-[100px] lg:px-[100px] px-5'>
                    <div className="h-full w-full">
                        <TitleTag title='Award Winning Logistic Company' />
                        <Typography className='font-bold lg:text-[52px] text-[35px] lg:leading-[60px] mt-5'>
                            World Best Secure Safe and Trusted Logistic Services Provider
                        </Typography>
                    </div>
                    <div className='w-full flex lg:h-[100px] border-l-[3px] border-l-muuvGreen px-10 mt-5 lg:mt-0'>
                        <div>
                            <Typography>
                                Sed ut perspiciatis unde omnis iste voluptatem accusantium dolorem que laudantium totam eaque ipsa inventore veritatis quasi architecto beatae dolorem que laudantium totam eaque ipsa inventore veritatis quasi architecto beatae
                            </Typography>

                            <div className='mt-10 w-[300px]'>
                                <DownloadAppComponent />
                            </div>
                        </div>
                    </div>
                </div>

                {/* services*/}
                <div ref={servicesDiv}>
                    <div className="mt-[150px] lg:px-[100px] px-5 h-full">
                        <div className='lg:flex justify-between items-center w-full gap-x-[50px]'>
                            <div className='w-full'>
                                <TitleTag title='Our Services' />
                                <Typography className='font-bold text-[35px] lg:text-[52px] lg:leading-[60px] mt-5'>
                                    Trusted Logistic Service Provider
                                </Typography>

                                <div className='mt-5'>
                                    <div className="flex gap-3 items-center">
                                        <StopCircleIcon className='w-5 h-5 text-muuvGreen' />
                                        <Typography>revolutionary catalysts for change</Typography>
                                    </div>
                                    <div className="flex gap-3 items-center mt-3">
                                        <StopCircleIcon className='w-5 h-5 text-muuvGreen' />
                                        <Typography>catalysts for chang the Seamlessly</Typography>
                                    </div>
                                    <div className="flex gap-3 items-center mt-3">
                                        <StopCircleIcon className='w-5 h-5 text-muuvGreen' />
                                        <Typography>business applications through</Typography>
                                    </div>
                                    <div className="flex gap-3 items-center mt-3">
                                        <StopCircleIcon className='w-5 h-5 text-muuvGreen' />
                                        <Typography>procedures whereas processes</Typography>
                                    </div>

                                    <div className="mt-10">
                                        <Typography>
                                            Distinctively exploit optimal alignments for intuitive business applications through revolutionary catalysts for chang the Seamlessly optimal optimal alignments for intuitive. Distinctively exploit optimal alignments for intuitive business applications through revolutionary catalysts for chang the Seamlessly optimal optimal alignments for intuitive.
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full mt-10 lg:mt-0'>
                                <img src={muuveBike} alt="" className='flex ml-auto mr-auto' />
                            </div>
                        </div>
                    </div>

                    <div className="mt-[150px] lg:px-[100px] px-5">
                        <div className='lg:grid lg:grid-cols-3 gap-x-[30px]'>
                            {
                                ["", "", ""].map((item, i) => (
                                    <div key={i} className='lg:mb-0 mb-5 flex flex-col justify-center items-center rounded-[10px] bg-muuvGreen text-white p-10'>
                                        <img src="https://www.pngkey.com/png/full/514-5146715_delivery-nz-wide-truck-icon-white.png" alt="truck" className='mr-auto ml-auto flex w-[200px]' />

                                        <Typography className='font-semibold text-lg text-center mt-5'>
                                            On Time Delivery
                                        </Typography>
                                        <Typography className='mt-3 text-center'>
                                            We always provide people a complete solution focused of any business.
                                        </Typography>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                {/* About us */}
                <div ref={aboutDiv} className="mt-[150px] lg:px-[100px] px-5">
                    <div className='lg:flex justify-between items-center w-full gap-x-[50px]'>
                        <div className='w-full mb-10 lg:mb-0'>
                            <img src={youngMan} alt="" className='flex ml-auto mr-auto' />
                        </div>
                        <div className='w-full'>
                            <TitleTag title='About Our Company' />
                            <Typography className='font-bold lg:text-[52px] text-[35px] lg:leading-[60px] mt-5'>
                                Trusted Logistic Service Provider
                            </Typography>

                            <div className='mt-5'>
                                <div className="flex gap-3 items-center">
                                    <StopCircleIcon className='w-5 h-5 text-muuvGreen' />
                                    <Typography>revolutionary catalysts for change</Typography>
                                </div>
                                <div className="flex gap-3 items-center mt-3">
                                    <StopCircleIcon className='w-5 h-5 text-muuvGreen' />
                                    <Typography>catalysts for chang the Seamlessly</Typography>
                                </div>
                                <div className="flex gap-3 items-center mt-3">
                                    <StopCircleIcon className='w-5 h-5 text-muuvGreen' />
                                    <Typography>business applications through</Typography>
                                </div>
                                <div className="flex gap-3 items-center mt-3">
                                    <StopCircleIcon className='w-5 h-5 text-muuvGreen' />
                                    <Typography>procedures whereas processes</Typography>
                                </div>

                                <div className="mt-10">
                                    <Typography>
                                        Distinctively exploit optimal alignments for intuitive business applications through revolutionary catalysts for chang the Seamlessly optimal optimal alignments for intuitive. Distinctively exploit optimal alignments for intuitive business applications through revolutionary catalysts for chang the Seamlessly optimal optimal alignments for intuitive. Distinctively exploit optimal alignments for intuitive business applications through revolutionary catalysts for chang the Seamlessly optimal optimal alignments for intuitive.
                                    </Typography>
                                </div>
                                {/* <div className="mt-10">
                                    <button className='h-[50px] w-[250px] bg-muuvGreen text-white'>
                                        Read More
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* contact us */}
                <div ref={contactDiv} className="lg:px-[100px] px-5 mt-[150px]">
                    <TitleTag title='Contact Us' />
                    <Typography className='font-bold lg:text-[40px] text-[35px] lg:leading-[60px]'>
                        We are available 24/7
                    </Typography>

                    <ContactComponent />
                </div>

                {/* footer */}
                <div className='mb-20 mt-[100px]'>
                    <CustomFooter />
                </div>
            </div>
        </div>
    )
}

export default HomePage
