import { Button, Carousel, Typography } from "@material-tailwind/react";


const HomeSlider = () => (
    <Carousel transition={{ duration: 2 }} loop={true} className="h-full">
        <div className="relative h-full w-full">
            <img
                src="https://static.vecteezy.com/system/resources/previews/021/869/195/non_2x/transportation-and-logistic-import-export-and-transport-industry-of-truck-container-cargo-ship-generative-ai-free-photo.JPG"
                alt="slide 1"
                className="h-full w-full object-cover"
            />
            <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
                <div className="w-3/4 text-center md:w-2/4">
                    <Typography
                        variant="h1"
                        color="white"
                        className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                    >
                        The Beauty of Nature
                    </Typography>
                    <Typography
                        variant="lead"
                        color="white"
                        className="mb-12 opacity-80"
                    >
                        It is not so much for its beauty that the forest makes a claim
                        upon men&apos;s hearts, as for that subtle something, that quality
                        of air that emanation from old trees, that so wonderfully changes
                        and renews a weary spirit.
                    </Typography>
                    <div className="flex justify-center gap-2">
                        <Button size="lg" color="white">
                            Explore
                        </Button>
                    </div>
                </div>
            </div>
        </div>

        <div className="relative h-full w-full">
            <img
                src="https://globconlogistics.com/uploads/images/news/Service1609695889.jpg"
                alt="slide 2"
                className="h-full w-full object-cover"
            />
            <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
                <div className="w-3/4 text-center md:w-2/4">
                    <Typography
                        variant="h1"
                        color="white"
                        className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                    >
                        The Beauty of Nature
                    </Typography>
                    <Typography
                        variant="lead"
                        color="white"
                        className="mb-12 opacity-80"
                    >
                        It is not so much for its beauty that the forest makes a claim
                        upon men&apos;s hearts, as for that subtle something, that quality
                        of air that emanation from old trees, that so wonderfully changes
                        and renews a weary spirit.
                    </Typography>
                    <div className="flex justify-center gap-2">
                        <Button size="lg" color="white">
                            Explore
                        </Button>
                    </div>
                </div>
            </div>
        </div>

        <div className="relative h-full w-full">
            <img
                src="https://files.techmahindra.com/static/img/logistic.jpg"
                alt="slide 3"
                className="h-full w-full object-cover"
            />
            <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
                <div className="w-3/4 text-center md:w-2/4">
                    <Typography
                        variant="h1"
                        color="white"
                        className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                    >
                        The Beauty of Nature
                    </Typography>
                    <Typography
                        variant="lead"
                        color="white"
                        className="mb-12 opacity-80"
                    >
                        It is not so much for its beauty that the forest makes a claim
                        upon men&apos;s hearts, as for that subtle something, that quality
                        of air that emanation from old trees, that so wonderfully changes
                        and renews a weary spirit.
                    </Typography>
                    <div className="flex justify-center gap-2">
                        <Button size="lg" color="white">
                            Explore
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </Carousel>
);

export default HomeSlider;