import { useState, useEffect } from "react";
import {
    Navbar,
    MobileNav,
    Typography,
    IconButton,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import logo from "../images/muuvLogo.png"
import { EnvelopeIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/24/outline"
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

interface navProps {
    homeClick?: () => void
    servicesClick?: () => void
    aboutClick?: () => void
    contactClick?: () => void
}

const CustomNavbar = ({ homeClick, servicesClick, aboutClick, contactClick }: navProps) => {
    const [openNav, setOpenNav] = useState(false);

    useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false),
        );
    }, []);


    //nav list
    const navList = (
        <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-semibold"
            >
                <Link to="#" onClick={homeClick} className="flex items-center">
                    Home
                </Link>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-semibold"
            >
                <Link to="#" onClick={servicesClick} className="flex items-center">
                    Services
                </Link>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-semibold"
            >
                <Link to="#" onClick={aboutClick} className="flex items-center">
                    About
                </Link>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-semibold"
            >
                <Link to="#" onClick={contactClick} className="flex items-center">
                    Contact
                </Link>
            </Typography>
        </ul>
    );

    return (
        <>
            <div className="w-full lg:py-4 lg:px-[100px] py-2 px-4 bg-muuvGreen text-white lg:flex lg:justify-between hidden">
                <div className="lg:flex gap-5">
                    <div className="flex gap-2 items-center">
                        <MapPinIcon className="h-5 w-5" />
                        <Typography className="text-[14px]">5th Floor Elephant House, 214 Broad Street Marina Lagos</Typography>
                    </div>
                    <div className="h-5 border-l-[2px] border-l-white hidden lg:block"></div>
                    <div className="flex gap-2 items-center mt-2 lg:mt-0">
                        <EnvelopeIcon className="h-5 w-5" />
                        <Typography className="text-[14px]">info.ng@movis-logistics.com</Typography>
                    </div>
                </div>
                <div className="flex gap-5 mt-2 lg:mt-0">
                    <div className="flex gap-5 items-center">
                        <Link to="#">
                            <FaFacebookF className="h-4 w-4" />
                        </Link>
                        <Link to="#">
                            <FaTwitter className="h-4 w-4" />
                        </Link>
                        <Link to="#">
                            <FaInstagram className="h-4 w-4" />
                        </Link>
                    </div>
                    <div className="h-5 border-l-[2px] border-l-white"></div>
                    <div className="flex gap-2 items-center">
                        <PhoneIcon className="h-5 w-5" />
                        <Typography className="text-[14px]">+234 704 668 7521</Typography>
                    </div>
                </div>
            </div>
            <Navbar className="sticky top-0 z-10 h-max max-w-full rounded-none px-4 py-2 lg:px-[100px] lg:py-6">
                <div className="flex items-center justify-between text-blue-gray-900">
                    <Link to="#">
                        <img src={logo} alt="logo" className="w-[100px]" />
                    </Link>
                    <div className="flex items-center gap-4">
                        <div className="mr-4 hidden lg:block">{navList}</div>

                        <IconButton
                            variant="text"
                            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                            ripple={false}
                            onClick={() => setOpenNav(!openNav)}
                        >
                            {openNav ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    className="h-6 w-6"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </IconButton>
                    </div>
                </div>

                {/* mobile */}
                <MobileNav open={openNav}>
                    {navList}
                </MobileNav>
            </Navbar>
        </>
    );
}

export default CustomNavbar