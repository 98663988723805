const ContactMap = () => {
  return (
    <>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.503199118142!2d3.378297873649342!3d6.457746223926734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b0a5400f1ff%3A0x31e6189ec09368d3!2sElephant%20House!5e0!3m2!1sen!2sgh!4v1705408475128!5m2!1sen!2sgh"
          width="100%"
          height="400"
          style={{ border: "0" }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="location-map"
        />
      </div>
    </>
  );
};

export default ContactMap;
