import { DevicePhoneMobileIcon } from "@heroicons/react/24/outline";
import whiteLogo from "../images/muuvLogoWhite.png";
import { Link } from "react-router-dom";
import { BiLogoFacebook, BiLogoWhatsapp } from "react-icons/bi";
import { AiOutlineTwitter, AiOutlineInstagram } from "react-icons/ai";
import DownloadAppComponent from "./downloadAppComponent";

const CustomFooter = () => {
  return (
    <>
      <div className="lg:h-[520px] h-auto bg-[#373737] text-white lg:px-[100px] px-5 lg:pt-[100px] pt-[40px] pb-20">
        <div className="lg:grid lg:grid-cols-4">
          <div className="lg:w-[200px]">
            <img className="w-[150px]" src={whiteLogo} alt="logo" />

            <p className="mt-[35px] text-[14px] font-[400] leading-[21px]">
              5th Floor Elephant House, 214 Broad Street Marina Lagos
            </p>

            <div className="mt-[45px] flex items-center gap-[12px]">
              <DevicePhoneMobileIcon className="h-5 w-5" />
              <Link
                to="tel:+2347046687521"
                className="text-[14px] font-[400] leading-[21px]"
              >
                +234 704 668 7521
              </Link>
            </div>
          </div>

          <div className="w-full">
            <p className="font-[600] text-[18px] leading-[24px]">Partners</p>
            <div className="mt-[35px]">
              <Link
                to="#"
                className="font-[400] text-[14px] leading-[21px] hover:text-yarpOrange"
              >
                Muuve Rider
              </Link>

              <div className="mt-[14px]">
                <Link
                  to="#"
                  className="font-[400] text-[14px] leading-[21px] hover:text-yarpOrange"
                >
                  Muuve Partner
                </Link>
              </div>
            </div>
          </div>

          <div className="w-full">
            <p className="font-[600] text-[18px] leading-[24px]">Company</p>
            <div className="mt-[35px]">
              <Link
                to="#"
                className="font-[400] text-[14px] leading-[21px] hover:text-yarpOrange"
              >
                About Movis
              </Link>

              <div className="mt-[14px]">
                <Link
                  to="#"
                  className="font-[400] text-[14px] leading-[21px] hover:text-yarpOrange"
                >
                  Know Our Team
                </Link>
              </div>
            </div>
          </div>



          <div className="w-full">
            <p className="font-[600] text-[18px] leading-[24px]">
              Follow Us On
            </p>
            <div className="mt-[35px] flex gap-[15px] items-center">
              <Link
                to="#"
                target="_blank"
                className="h-[32px] w-[32px] rounded-full border-[1px] border-white flex justify-center items-center text-white hover:bg-yarpOrange hover:border-yarpOrange"
              >
                <BiLogoFacebook className="h-5 w-5" />
              </Link>
              <Link
                to="#"
                target="_blank"
                className="h-[32px] w-[32px] rounded-full border-[1px] border-white flex justify-center items-center text-white hover:bg-yarpOrange hover:border-yarpOrange"
              >
                <AiOutlineTwitter className="h-5 w-5" />
              </Link>
              <Link
                to="#"
                target="_blank"
                className="h-[32px] w-[32px] rounded-full border-[1px] border-white flex justify-center items-center text-white hover:bg-yarpOrange hover:border-yarpOrange"
              >
                <AiOutlineInstagram className="h-5 w-5" />
              </Link>
              <Link
                to="#"
                target="_blank"
                className="h-[32px] w-[32px] rounded-full border-[1px] border-white flex justify-center items-center text-white hover:bg-yarpOrange hover:border-yarpOrange"
              >
                <BiLogoWhatsapp className="h-5 w-5" />
              </Link>
            </div>
            <div className="mt-[25px]">
              <p className="font-[400] text-[14px] leading-[21px]">
                Download Muuve
              </p>
            </div>
            <div className="mt-[13px]">
              <DownloadAppComponent />
            </div>
          </div>
        </div>

        <hr className="mt-10" />

        <div className="lg:flex justify-between items-center mt-[40px] pb-[140px] lg:pb-0">
          <div className="flex gap-[28px] items-center">
            <Link
              to="/privacy"
              className="font-[400] text-[14px] leading-[21px] hover:text-yarpOrange"
            >
              Privacy policy
            </Link>
            <Link
              to="/support"
              className="font-[400] text-[14px] leading-[21px] hover:text-yarpOrange"
            >
              Support
            </Link>
          </div>
          <div className="mt-5 lg:mt-0">
            <p className="font-[400] text-[14px] leading-[21px]">
              © 2024 Copyright Movis Logistics
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomFooter;
